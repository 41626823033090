import { axiosApiInstance } from "@/plugins/axios";

function getAdvanceBalance() {
  this.loading = true;
  axiosApiInstance
    .get(process.env.VUE_APP_BACKEND + "/advances/balance", {
      params: {
        project_id: this.project_id,
        dates: this.criteria.selectedPeriod,
      },
    })
    .then((response) => {
      this.balance = response.data.data;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      console.log(error);
    });
}

export { getAdvanceBalance };
