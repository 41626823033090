<template>
  <div>
    <div>
      <h6 class="subtitle ">INPUT DISTRIBUTION</h6>
    </div>
    <bar-graph :series="series" :chartOptions="chartOptions"></bar-graph>
    <b-link href="#" v-show="false">View Full Report</b-link>
  </div>
</template>

<script>
// import BarGraph from '../../dashboard/components/BarGraph.vue';
import BarGraph from './BarGraph.vue';
import axios from "axios";

export default {
  components: { BarGraph },
  props: {
    criteria:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },

  data(){
    return {
      series:[],
      categories:[],
      chartData:[],
      advanceBreakdown:[],
      chartOptions:{},
      startDate:'',
      endDate:'',
    }
  },

  computed: {
    project_id(){
      return this.$store.state.project_id;
    }
  },
  
  methods: {
    getAdvanceBreakdown(dataObj){

      axios.get(process.env.VUE_APP_BACKEND+"/advances/breakdown", {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
        params: {
          project_id: this.project_id, 
          dates: this.criteria.selectedPeriod,
        }
      })
      .then(function (response) {
        dataObj.advanceBreakdown = response.data.data;
        dataObj.getchartData();
        // dataObj.loadingAdvanceBalance = false;
      })
    },
    getchartData(){
      for(const x of this.advanceBreakdown){
        this.categories.push(x.advance_type);
        this.chartData.push(x.total);
      }
      // console.log(this.chartData);
      // console.log(this.categories);
      this.series = [
              {
                data: this.chartData,
              },
            ];

      this.chartOptions = {
                  chart: {
                    type: "bar",
                    height: 350,
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 4,
                      horizontal: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  xaxis: {
                    categories: this.categories
                  },
                };
    },
  },

  mounted() {
    // this.getchartData();
    this.getAdvanceBreakdown(this);
  },
  
  };
</script>

<style>
</style>