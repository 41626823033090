<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">ADVANCES</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          squared
          class="btn-outline-success mr-2"
          @click="routePage"
          :disabled="!can('modify', 'inputs')"
        >
          <b-icon-gear></b-icon-gear> Manage Inputs
        </b-button>
        <b-button
          :disabled="!can('modify', 'inputs')"
          class="btn-primary"
          v-analytics:click="['new_advance', { element: 'FarmInputs' }]"
          v-b-modal.add-advance
        >
          <!-- <b-button v-b-modal.add-advance variant="primary"> -->
          <b-icon-plus></b-icon-plus> New Advance
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <b-col>
          <div class="d-flex">
            <b-select
              class="form-select mr-2"
              v-model="criteria.selectedSupplier"
              v-analytics:change="[
                'filter_advance_supplier',
                { element: 'FarmInputs' },
              ]"
            >
              <option selected value="">All Suppliers</option>
              <option
                v-for="(supplier, idx) in suppliers"
                :key="idx"
                :value="supplier.value"
              >
                {{ supplier.text }}
              </option>
            </b-select>
            <b-form class="ml-auto-">
              <b-form-input
                placeholder="Search Name"
                type="search"
                class="search"
                v-model="criteria.searchfield"
                v-analytics:keyup="[
                  'search_advance_supplier',
                  { element: 'FarmInputs' },
                ]"
              ></b-form-input>
            </b-form>
            <produce-date-picker
            class="pl-2"
            @dates-selected="cfilterByDate"
          >
          </produce-date-picker>
          </div>
        </b-col>
        <div class="col-auto">
          <div class="sidebar-width text-right">
            <button
              class="btn-opt primary"
              @click="onSuccess"
              v-analytics:click="['refresh_advance', { element: 'FarmInputs' }]"
            >
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button
              class="btn-opt primary"
              v-print="'#advance-records'"
              v-analytics:click="['print_advance', { element: 'FarmInputs' }]"
            >
              <b-icon-printer></b-icon-printer> Print
            </button>
            <download-excel
              class="btn btn-opt primary"
              :data="advanceRecords"
              :fields="csvFields"
              v-analytics:click="[
                'export_advances_csv',
                { element: 'Advances' },
              ]"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>
    <b-row class="m-0">
      <b-col class="pt-2">
        <div class="text-capitalize">
          <b-table
            id="advance-records"
            ref="advanceRecords"
            class="table-hover"
            @row-clicked="view_user"
            :fields="fields"
            :items="advanceRecords"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="loadingAdvanceData"
            :filter="criteria"
            :filter-function="filterItems"
            @filtered="updateRows"
            show-empty
            thead-class="bg-primary-tb text-primary-tb border-primary-tb"
          >
            <template #empty>
              <h4>No advances given out</h4>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(username)="data">
              <b-avatar icon="person">{{ data.item.username[0] }}</b-avatar>
              {{ data.item.username }}
            </template>
            <template #cell(usertype)="data">
              <usertype-pill :user_type="data.item.user_type">
              </usertype-pill>
            </template>

            <template #cell(amount)="data">
              {{ data.item.amount.toLocaleString() }}
            </template>
            <template #cell(action)="">
              <div>
                <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="advance-records"
            class="b-pagination-class"
          ></b-pagination>
        </div>
      </b-col>
      <b-col cols="auto pt-2">
        <div class="sidebar">
          <total-advances :criteria="criteria" :key="'total'+criteria.selectedPeriod"></total-advances>
          <input-distribution :criteria="criteria"  :key="'distribution'+criteria.selectedPeriod"></input-distribution>
        </div>
      </b-col>
    </b-row>

    <add-advance
      :modal_id="modal_id"
      @AdditionSuccess="onSuccess"
    ></add-advance>
    <farmer-modal @reloadParentAdvance="onSuccess"></farmer-modal>
  </div>
</template>

<script>
import InputDistribution from "../../components/InputDistribution.vue";
import UsertypePill from "@/components/usertype_pill/UsertypePill.vue";
// import { axiosApiInstance } from "@/plugins/axios.js"
import moment from "moment";
import AddAdvance from "@/components/add_advance/AddAdvance.vue";
import FarmerModal from "@/components/farmer_modal/Farmer.vue";
import { can } from "@/modules/auth/utils.js";
import dateMixin from "../../../produce/date_mixin";
import ProduceDatePicker from "../../../produce/components/ProduceDatePicker.vue"
import TotalAdvances from "./components/total_advances/TotalAdvances.vue";
import {
  routePage,
  onSuccess,
  getAdvanceData,
  onSubmitInput,
  view_user,
} from "./actions.js";
import { Periods } from '../../../produce/action';

export default {
  components: { InputDistribution, FarmerModal, AddAdvance,ProduceDatePicker, UsertypePill, TotalAdvances, },
  mixins:[dateMixin],
  data() {
    let fields = [
      { key: "username", label: "Name" },
      { key: "usertype", label: "" },
      "contact",
      { key: "amount", label: "Input Amount" },
      { key: "season", label: "Issue Date" },
      { key: "action", label: "" },
    ];
    let csvFields = {
      Name: "username",
      Contact: "contact",
      "Input Amount": "amount",
      "Date Taken": {
        callback: (time) => {
          return moment(time).format("MMMM Do YYYY");
        },
      },
    };
    let suppliers = [
      { text: "Farmer", value: "farmer" },
      { text: "Village Agent", value: "va" },
      { text: "Farmer Group", value: "farmer_grup" },
    ];

    return {
      fields,
      csvFields,
      perPage: 10,
      currentPage: 1,
      loadingAdvanceData: false,
      loadingAdvanceBalance: false,
      loading: false,
      loadingInputs: false,
      AdditionSuccess: false,
      loadingInputSubmission: false,
      supplier_type: "",
      farmerId: "",
      inputObject: {},
      input: {
        variety: "",
      },
      suppliers,
      advanceRecords: [],
      advanceGiven: [],
      advanceBalance: 0,
      totalAdvanceBalance: 0,
      advanceToBeGiven: [],
      advanceInputs: [],
      modal_id: "add-advance",
      selectedPeriod:"",
      rows: 0,
      criteria:{
        selectedSupplier:"",
        searchfield:"",
        selectedPeriod: Periods[""]
      }
    };
  },
  mounted: function() {
    this.dateInit();
    this.getAdvanceData(this);
    // this.getAdvanceBreakdown(this);
    this.$analytics.track("open_advances", { element: "Farm Inputs" });
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
  },
  methods: {
    can,
    routePage,
    onSuccess,
    getAdvanceData,
    onSubmitInput,
    view_user,
    updateRows(_, count) {
      this.rows = count;
    },
    filterItems(row, criteria) {
      let filters = [];
      if (criteria.selectedSupplier) {
        filters.push(row["user_type"] == criteria.selectedSupplier);
      }
      if (criteria["selectedPeriod"]) {
        filters.push(
          moment(row["season"], "YYYY-MM-DD").isBetween(
            ...this.criteria.selectedPeriod
          )
        );
      }
      if (criteria["searchfield"].length==2) {
        filters.push(
          row["username"]
            .toLowerCase()
            .includes(criteria["searchfield"].toLowerCase())
        );
      }
      return !filters.includes(false);
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>

<style lang="scss" scoped></style>
