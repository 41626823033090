<template>
  <b-alert variant="info" show class="alert-totals">
    <h6 class="title">Total Advance Balance</h6>
    <div class="text-center" v-if="loading">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <h3 class="m-0" v-if="!loading">
      UGX {{ balance.toLocaleString() }}
    </h3>
  </b-alert>
</template>
<script>
import { getAdvanceBalance } from "./actions";
export default {
  props: {
    criteria: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      balance: 0,
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
  },
  methods: {
    getAdvanceBalance,
  },
  mounted() {
    this.getAdvanceBalance();
  },
};
</script>
