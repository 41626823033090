import { axiosApiInstance } from "@/plugins/axios";
import { bus } from "@/main.js";
function getAdvanceData(dataObj) {
    this.loadingAdvanceData = true;
    axiosApiInstance
      .get(process.env.VUE_APP_BACKEND + "/advances/", {
        params: { 
          project_id: this.project_id,
          dates:this.criteria.selectedPeriod,
        },
      })
      .then(function (response) {
        dataObj.advanceRecords = response.data.data;
        dataObj.rows = response.data.data.length;
        dataObj.loadingAdvanceData = false;
      });
  }


  function view_user(item) {
    bus.$emit("farmer-view", {
      name: item.username,
      data: item,
      farmer_id: item.user_id ? item.user_id : item.farmer_id,
      tab: "farm_inputs",
    });
  }
  // getAdvanceBreakdown(dataObj){
  //   axiosApiInstance.get(process.env.VUE_APP_BACKEND+"/advances/breakdown", {
  //     headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
  //     params: {partner_id: "AK/OT/0008", season:JSON.stringify(['2021-01-01','2021-12-31'])}
  //   })
  //   .then(function (response) {
  //     dataObj.advanceBreakdown = response.data.data;
  //     // dataObj.loadingAdvanceBalance = false;
  //   })
  // },
  function onSubmitInput(event) {
    event.preventDefault();
    this.inputObject.advance_quantity = 0;
    this.inputObject.advance_cost = 0;
    this.advanceToBeGiven.push(this.inputObject);
    // alert("Input successfully added")
    this.$vToastify.success("Input successfully added");
    // this.addInputSuccess = true;
  }
  // deleteRecord(data){
  //   var index = data.index;
  //   var currentAmount = this.advanceToBeGiven[index].advance_cost;
  //   this.totalAdvanceBalance -= currentAmount;
  //   this.advanceToBeGiven.splice(index,1);
  //   this.$refs.advanceTable.refresh();
  // },
  function onSuccess() {
    this.getAdvanceData(this);
    this.$refs.advanceRecords.refresh();
  }
  function routePage() {
    this.$router.push({ path: "/settings/farm_input" });
  }
export {
    routePage,onSuccess,getAdvanceData,
    onSubmitInput,view_user

}